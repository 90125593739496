.loginWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    min-height: 100vh;
    position: relative;

    .topBgr {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: #1976d2;
    }

    & .loginForm {
        z-index: 999;
        width: 525px;
        margin: 0 auto;
        background-color: #fff;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 32px;

        & .logo {
            text-align: center;

            & img {
                width: 220px;
                height: 120px;
            }
        }
    }
}
