.wrapper {
    font-size: 14px;
    color: #000000de;

    :global {
        .ant-modal-content {
            height: 100%;
        }
        .ant-modal-header {
            background-color: #1976d2;
            height: 64px;
            line-height: 64px;
            padding: 0 0;
        }

        .ant-modal-title {
            color: #fff;
            line-height: 64px;
            padding-left: 24px;
        }

        .ant-modal-close-x {
            color: #fff;
        }

        .ant-menu-horizontal {
            margin: 0 auto;
            justify-content: center;
        }

        .ant-modal-body {
            max-height: calc(100vh - 100px);
        }
    }

    & .csvTitle {
        color: #0000008a;
        font-size: 14px;
        font-weight: 500;
    }
}

.modelWrapper {
    :global {
        .ant-collapse > .ant-collapse-item > .ant-collapse-header {
            display: inherit;
        }
    }
}

.typeWrapper {
    text-align: center;
    margin-bottom: 15px;

    & .type {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        color: #000000de;
        cursor: pointer;
    }

    & .typeActive {
        border-bottom: 2px solid #82b1ff;
    }
}
