.formWrapper {
    display: flex;
    justify-content: space-between;

    .form {
        width: -webkit-fill-available;
    }

}

.priority {
    margin-bottom: 20px;

    .action {
        margin-right: 20px;
    }
}

.headerCollapseWrapper {
    width: 100%;
}

.collapseWrapper {
    :global {
        .ant-collapse {
            max-height: calc(100vh - 260px);
            overflow-y: auto;
        }
    }
}
