.wrapper {
    :global {
        .ant-modal-content {
            max-height: calc(100vh - 100px);
        }
        .ant-modal-header {
            background-color: #1976d2;
            height: 64px;
            line-height: 64px;
            padding: 0 0;
        }

        .ant-modal-title {
            color: #fff;
            line-height: 64px;
            padding-left: 24px;
        }

        .ant-modal-close-x {
            color: #fff;
        }

        .ant-menu-horizontal {
            margin: 0 auto;
            justify-content: center;
        }

        .ant-modal-body {
            max-height: calc(100vh - 100px);
        }
    }

    .action {
        display: flex;
        justify-content: space-between;
    }
}