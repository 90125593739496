.logo {
    height: 64px;
    background: #005fb6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;

    & img {
        width: 36px;
        height: 36px;
    }

    & span {
        font-size: 20px;
        font-weight: 500;
        margin-left: 16px;
    }
}

.header {
    background: #1a76d2;
    padding: 0 16px;
    display: flex;
    align-items: center;
    width: 100%;

    & .userWrapper {
        cursor: pointer;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #fff;
    }
}

.content {
    margin: 16px 16px 0;
}
